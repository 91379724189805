<template>
    <a  :class="{selected: selected}"
        @click="submitClick" 
        @mouseover="submitMouseover"
        @mouseout="submitMouseout"
        href="#"
    >
        {{menuitem.name}}
    </a>
</template>
<script>
import MenuMouseEvents from '../menuevents';
export default {
    props: ['menuitem'],
    data(){
        return {
            selected: false            
        }
    },
    methods: {
        submitClick(){  
            let selectedHmenuItem = null;          
            let showVmenu = false;
            if (!this.selected){                
                showVmenu = true;
                this.selected = true;
                selectedHmenuItem = this;
            }else{
                showVmenu = false;
                this.selected = false;
                selectedHmenuItem = null;
            }         
            // Unselect previously selected hmenu item
            const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
            if (prevSelectedHmenuItem !== null && prevSelectedHmenuItem != this){
                prevSelectedHmenuItem.selected = false;
            }
            
            const vmenus = this.menuitem.vmenus;
            if (vmenus.length == 0){
                showVmenu = false;
                let componentName = "EmptyComponent";
                if(selectedHmenuItem !== null)
                    componentName = this.menuitem.componentName;
                this.$store.commit('mainComponentName', componentName);
                //this.$store.commit('vmenuActive', false);
                //let prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;                
            }  
                        
            this.$store.commit('selectedHmenuItem', selectedHmenuItem);
            this.$store.commit('menuMouseEvent', MenuMouseEvents.MENU_EVENT_TYPE_CLICK);
            this.$store.commit('vmenuActive', showVmenu);
        },
        submitMouseover(event){
            this.$store.commit('mouseOverHmenuItem', this);              
            this.$store.commit('menuMouseEvent', MenuMouseEvents.MENU_EVENT_TYPE_MOUSEOVER);
            this.$store.commit('vmenuActive', true);               
        },
        submitMouseout(event){            
            const selectedHmenuItem = this.$store.state.selectedHmenuItem;
            if (selectedHmenuItem === null)
                this.$store.commit('vmenuActive', false);
            this.$store.commit('menuMouseEvent', MenuMouseEvents.MENU_EVENT_TYPE_MOUSEOUT);    
        }
    }
}
</script>
