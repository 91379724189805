<template>
    <ul id="hmenu"> 
        <li v-for="link in links" :key="link.id">
            <hmenu-item :menuitem="link"></hmenu-item>
        </li>
        <!-- <li id="quicksearch" ><input type="text" ></li> -->
    </ul>
</template>
<script>
//import util from '../util';
import HmenuItem from './HmenuItem.vue';
export default { 
    props:['links'],
    data(){
        return {
        }
    },
    methods: {
        
    },
    components:{
        HmenuItem
    } 
}
</script>

<style scoped>
    .hactive{
        position: absolute;
        display: block !important;    
        max-width: 50%;
        height: 100%;    
        overflow: auto;
        background-color: rgba(0, 153, 102, 10);    
    }

    
    ul#hmenu{
        display: inline-block;
        list-style: none;
        margin: 0;
        padding-left: 0;          
        overflow: hidden;   
        width: 100%;  
    }    
    
    ul#hmenu > li#quicksearch{       
        float: left;
        padding-right: 4px;
    }

    ul#hmenu > li#quicksearch > input{        
        margin-left: 4px;
        padding-top: -4px;
    }

    ul#hmenu > li{        
        margin-left: 1px;
        margin-right: 1px;            
    }

    ul#hmenu > li:not(#quicksearch){
        float: right;
    }

    ul#hmenu > li:not(#quicksearch) > a{
        font-weight: bold;
        color: #fff; 
        cursor: pointer;                
        padding: 2px;
        text-align: center;
        text-shadow: 1px 1px 1px #000;
        text-decoration: none;
        font-size: var(--menu-font-size);
    }
    ul#hmenu > li:not(#quicksearch) > a:hover{
        color: var(--menu-hover-color);
    }

    ul#hmenu > li:not(#quicksearch) > a.selected{
        outline: 0;
        color: var(--menu-selected-color);
    }    
</style>